import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
//import TextareaAutosize from '@mui/base/TextareaAutosize';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import SaveIcon from '@mui/icons-material/Save';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';
import swal from 'sweetalert';
import Allergen from './Allergen';

//import './css/sideBar.scss';
//import './css/dashboard.css';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KImagesTable from './KImagesTable';
import TextField from '@mui/material/TextField';
import DropDown from './DropDown';

import noImage from './../images/noImage.png';
import idle from './../images/icons/idle_on.png';
import registered from './../images/icons/registered_on.png';
import active from './../images/icons/active_on.png';
import discontinued from './../images/icons/discontinued_on.png';

import soia_off from './../images/icons/soia_off.png';
import soia_on from './../images/icons/soia_on.png';
import arahide_off from './../images/icons/arahide_off.png';
import arahide_on from './../images/icons/arahide_on.png';
import dioxid_off from './../images/icons/dioxid_off.png';
import dioxid_on from './../images/icons/dioxid_on.png';
import grau_off from './../images/icons/grau_off.png';
import grau_on from './../images/icons/grau_on.png';
import lapte_off from './../images/icons/lapte_off.png';
import lapte_on from './../images/icons/lapte_on.png';
import mustar_off from './../images/icons/mustar_off.png';
import mustar_on from './../images/icons/mustar_on.png';
import oua_off from './../images/icons/oua_off.png';
import oua_on from './../images/icons/oua_on.png';
import peste_off from './../images/icons/peste_off.png';
import peste_on from './../images/icons/peste_on.png';
import porumb_off from './../images/icons/porumb_off.png';
import porumb_on from './../images/icons/porumb_on.png';
import seminte_off from './../images/icons/seminte_off.png';
import seminte_on from './../images/icons/seminte_on.png';

import askPrice_off from './../images/icons/askPrice_off.png';
import askPrice_on from './../images/icons/askPrice_on.png';
import askQuantity_off from './../images/icons/askQuantity_off.png';
import askQuantity_on from './../images/icons/askQuantity_on.png';
import canCombo_off from './../images/icons/canCombo_off.png';
import canCombo_on from './../images/icons/canCombo_on.png';
import canGenerateFidelityPoints_off from './../images/icons/canGenerateFidelityPoints_off.png';
import canGenerateFidelityPoints_on from './../images/icons/canGenerateFidelityPoints_on.png';
import hasPackaging_off from './../images/icons/hasPackaging_off.png';
import hasPackaging_on from './../images/icons/hasPackaging_on.png';
import hasRecipe_off from './../images/icons/hasRecipe_off.png';
import hasRecipe_on from './../images/icons/hasRecipe_on.png';
import isDeliverable_off from './../images/icons/isDeliverable_off.png';
import isDeliverable_on from './../images/icons/isDeliverable_on.png';
import isDiscountable_off from './../images/icons/isDiscountable_off.png';
import isDiscountable_on from './../images/icons/isDiscountable_on.png';
import isForSale_off from './../images/icons/isForSale_off.png';
import isForSale_on from './../images/icons/isForSale_on.png';
import isFractionable_off from './../images/icons/isFractionable_off.png';
import isFractionable_on from './../images/icons/isFractionable_on.png';
import isNew_off from './../images/icons/isNew_off.png';
import isNew_on from './../images/icons/isNew_on.png';
import isPurchased_off from './../images/icons/isPurchased_off.png';
import isPurchased_on from './../images/icons/isPurchased_on.png';
import isSellable_off from './../images/icons/isSellable_off.png';
import isSellable_on from './../images/icons/isSellable_on.png';
import isSoldAlone_off from './../images/icons/isSoldAlone_off.png';
import isSoldAlone_on from './../images/icons/isSoldAlone_on.png';
import selfService_off from './../images/icons/selfService_off.png';
import selfService_on from './../images/icons/selfService_on.png';
import useScale_off from './../images/icons/useScale_off.png';
import useScale_on from './../images/icons/useScale_on.png';

import grossWeight_on from './../images/icons/grossWeight.png';

import isNumeric from 'validator/lib/isNumeric';

import Client from './Client';
import Config from './ClientConfig';
import BackOfficeConfig from './../Config';

let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));



class Product extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      expanded: false,
      edit: false,
      allowEdit: false,
      product: {
        name: "",
        description: "",
        image: noImage,
        createdOn: "",
        ean: "",
        sku: "",
        relation: "",
        grossWeight: 0.0,
        spec: "",
        pClass: {
          name: "",
          image: noImage
        },
        pCategory: {
          name: "",
          image: noImage
        },
        pSubCategory: {
          name: "",
          image: noImage
        },
        status: 0,
        unitValue: 0,
        kcal: 0,
        allergensList: [],
        askPrice: false,
        askQuantity: false,
        canCombo: false,
        canGenerateFidelityPoints: false,
        hasPackaging: false,
        hasRecipe: false,
        isDeliverable: false,
        isDiscountable: false,
        isForSale: false,
        isFractionable: false,
        isNew: false,
        isPurchased: false,
        isSellable: false,
        isSoldAlone: false,
        selfService: false,
        useScale: false,
        images: []
      },
      allergensList: [],
      somethingHasChanged: false,
      name_valid: false,
      description_valid: false,
      ean_valid: false,
      sku_valid: false,
      class_valid: false,
      category_valid: false,
      subcategory_valid: false,
      unitValue_valid: false,
      kcal_valid: false,
      vat_valid: false,

      vats: [],
      avats: [],
      classes: [],
      categories: [],
      subCategories: [],
      aclasses: [],
      acategories: [],
      asubCategories: [],
      //product_mainImage: null,
    };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.getElementByName = this.getElementByName.bind(this);
    this.imageUpdated = this.imageUpdated.bind(this);
    this.flagAllergen = this.flagAllergen.bind(this);
    this.flagOption = this.flagOption.bind(this);
    this.getClasses = this.getClasses.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.getSubCategories = this.getSubCategories.bind(this);
    this.getVats = this.getVats.bind(this);
    this.changeClass = this.changeClass.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.changeSubCategory = this.changeSubCategory.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeUnitType = this.changeUnitType.bind(this);
    this.changeVAT = this.changeVAT.bind(this);
    this.pushData = this.pushData.bind(this);
  }

  componentDidMount ( ) {
    if ( this.props.product ) {
      let prod = this.props.product;
      this.setState ( { product: prod } );
      if ( prod.name.length > 0 )
        this.setState ( { name_valid: true } );
      if ( prod.description.length > 0 )
        this.setState ( { description_valid: true } );
      if ( prod.ean.length > 0 )
        this.setState ( { ean_valid: true } );
      if ( prod.sku.length > 0 )
        this.setState ( { sku_valid: true } );

      if ( prod.pClass !== undefined )
        this.setState ( { class_valid: true } );
      if ( prod.pCategory !== undefined )
        this.setState ( { category_valid: true } );
      if ( prod.pSubCategory !== undefined )
        this.setState ( { subcategory_valid: true } );

      if ( prod.unitValue !== undefined )
        this.setState ( { unitValue_valid: true } );

      if ( prod.kcal !== undefined )
        this.setState ( { kcal_valid: true } );

      if ( prod.vat !== undefined )
        this.setState ( { vat_valid: true } );
    }

    if ( this.props.allowEdit === true )
      this.setState ({allowEdit: true})

    if ( this.props.isNew === true && this.props.allowEdit === true)
        this.handleEditClick (true);

    if ( this.props.allergensList )
      this.setState ( { allergensList: this.props.allergensList } );
  }

  componentDidUpdate ( prevProps ) {
    if ( this.props != prevProps ) {
      if ( this.props.product ) {
        let prod = this.props.product;
        this.setState ( { product: prod } );

        if ( prod.name.length > 0 )
          this.setState ( { name_valid: true } );
        if ( prod.description.length > 0 )
          this.setState ( { description_valid: true } );
        if ( prod.ean.length > 0 )
          this.setState ( { ean_valid: true } );
        if ( prod.sku.length > 0 )
          this.setState ( { sku_valid: true } );

        if ( prod.pClass !== undefined )
          this.setState ( { class_valid: true } );
        if ( prod.pCategory !== undefined )
          this.setState ( { category_valid: true } );
        if ( prod.pSubCategory !== undefined )
          this.setState ( { subcategory_valid: true } );

        if ( prod.vat !== undefined )
          this.setState ( { vat_valid: true } );
      }
      if ( this.props.allergensList )
        this.setState ( { allergensList: this.props.allergensList } );
    }
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  handleEditClick ( isNew = false ) {
    console.log ("---1---");
    console.log (this.state.allowEdit);
    console.log (isNew);
    if (!this.state.allowEdit && !isNew )
      return;
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
    if ( this.state.expanded === false && cEdit === true )
      this.state.expanded = true;
    if ( this.state.expanded === true && cEdit === false )
      this.state.expanded = false;
    //this.setState({ saveButtonVisible: cEdit });

    this.getClasses ( );
    this.getCategories ( );
    this.getSubCategories ( this.state.product.pCategory.iref );
    this.getVats ( );
  }

  getElementByName ( elements, name ) {    
    let e = {
      "iref" : null,
      "name" : "X",
      "image" : "X",
      "image_off" : "X"
    };
    if ( elements !== null && elements !== undefined ) {
      for ( var i = 0; i < elements.length; i++ ) {
        let el = elements [ i ];
        if ( el.length === 2 ) {
          if ( el [ 1 ].name === name ) e = el [ 1 ];
        } else {
          if ( el [ 0 ].name === name ) e = el [ 0 ];
        }        
      }
    }
    return e;    
  }

  imageUpdated ( ) {
    let queryData = {
      iref: this.state.product.iref
    }
    let rdata = this.state.client.getData ( Config.API_URL_PRODUCT + this.state.product.iref, queryData );
    if ( rdata ) {
      if ( rdata.productImage_product ) {
        let uProduct = this.state.product;
        uProduct.productImage_product = rdata.productImage_product;
        this.setState ( { product: uProduct } );
      }
    }
  }

  flagAllergen ( e, iref ) {
    let uProduct = this.state.product;    
    let attached = false;
    if ( uProduct.allergens ) {
      for ( var i = 0; i < uProduct.allergens.length; i++ ) {
        if ( uProduct.allergens[i].iref === iref ) {
          attached = true;
          uProduct.allergens.splice ( i, 1 );
        }
      }
    } else {
      uProduct.allergens = [];
    }
    if ( !attached ) {
      let allergensArr = Object.entries ( this.state.allergensList ); 
      for ( var i = 0; i < allergensArr.length; i++ ) {
        let el = allergensArr[i];
        if ( el.length === 2 ) {
          if ( el [ 1 ].iref === iref ) {
            uProduct.allergens.push ( el [ 1 ]);
          }
        } else {
          if ( el [ 0 ].iref === iref ) {
            uProduct.allergens.push ( el [ 0 ]);
          }
        }        
      }      
    }
    this.setState ( { product: uProduct } );
    this.setState ( { somethingHasChanged: true } );
  }

  flagOption ( e ) {
    let option = e.target.alt;
    let uProduct = this.state.product;
    uProduct [ option ] = !uProduct [ option ];
    this.setState ( { product: uProduct } );
    this.setState ( { somethingHasChanged: true } );
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    if ( id !== null) {
      let uProduct = this.state.product;
      switch ( id ) {
        case "name":
          uProduct.name = value;
          if ( value.length > 2 ) this.setState ( { name_valid: true } );
          else this.setState ( { name_valid: false } );
          break;
        case "description":
          uProduct.description = value;
          if ( value.length > 2 ) this.setState ( { description_valid: true } );
          else this.setState ( { description_valid: false } );
          break;
        case "ean":
          uProduct.ean = value;
          if ( value.length > 0 ) this.setState ( { ean_valid: true } );
          else this.setState ( { ean_valid: false } );
          break;
        case "sku":
          uProduct.sku = value;
          if ( value.length > 0 ) this.setState ( { sku_valid: true } );
          else this.setState ( { sku_valid: false } );
          break;
        case "unitValue":
          uProduct.unitValue = value;
          if ( isNumeric (value) ) this.setState ( { unitValue_valid: true } );
          else this.setState ( { unitValue_valid: false } );
          break;
        case "kcal":
          uProduct.kcal = value;
          if ( isNumeric (value) ) this.setState ( { kcal_valid: true } );
          else this.setState ( { kcal_valid: false } );
          break;
        case "relation":
          uProduct.relation = value;
          break;
        case "grossWeight":
          uProduct.grossWeight = parseFloat(value);
          break;          
        case "spec":
          uProduct.spec = value;
          break;
      }

      this.setState ( { product: uProduct } );
      this.setState ( { somethingHasChanged: true } );
    }
  }

  getVats ( ) {
    let queryData = {};
    let data = this.state.client.getData ( Config.API_URL_VATS, queryData );
    if ( data ) {
      let vats = {};
      for ( var i = 0; i < data.VATS.length; i++ ) {
        vats [ data.VATS [ i ].iref ] = data.VATS [ i ].name;
      }
      this.setState ( { vats: vats } );
      this.setState ( { avats: data.VATS } );
    }
  }

  getClasses ( ) {
    let data = this.state.client.getProductsClasses ( );
    if ( data ) {
      let classes = {};
      for ( var i = 0; i < data.productClasses.length; i++ ) {
        classes [ data.productClasses [ i ].iref ] = {          
          "name": data.productClasses [ i ].name,
          "image": data.productClasses [ i ].image
          }
      }
      this.setState ( { classes: classes } );
      this.setState ( { aclasses: data.productClasses } );
    }
  }

  getCategories ( ) {
    let data = this.state.client.getProductsCategories ( );
    if ( data ) {
      let categories = {};
      for ( var i = 0; i < data.productCategories.length; i++ ) {
        let newClass = {};
        categories [ data.productCategories [ i ].iref ] = {          
          "name": data.productCategories [ i ].name,
          "image": data.productCategories [ i ].image
          }
      }
      this.setState ( { categories: categories } );
      this.setState ( { acategories: data.productCategories } );
    }
  }

  getSubCategories ( category ) {
    let queryData = {
      "category": category
    };
    let data = this.state.client.getProductsSubCategories ( queryData );
    if ( data ) {
      let subCategories = {};
      for ( var i = 0; i < data.productSubCategories.length; i++ ) {
        subCategories [ data.productSubCategories [ i ].iref ] = {          
          "name": data.productSubCategories [ i ].name,
          "image": data.productSubCategories [ i ].image
          }
      }
      this.setState ( { subCategories: subCategories } );
      this.setState ( { asubCategories: data.productSubCategories } );
    }
  }

  changeClass ( e ) {
    let classSet = false;
    let uProduct = this.state.product;
    for ( var i = 0; i < this.state.aclasses.length; i++ ) {
      if ( this.state.aclasses [ i ].iref === e ) {
        uProduct.pClass = this.state.aclasses [ i ];
        classSet = true;
      }
    }
    this.setState ( { product: uProduct } );
    if ( classSet === true )
      this.setState ( { class_valid: true } );
    else
      this.setState ( { class_valid: false } );
    this.setState ( { somethingHasChanged: true } );
  }

  changeVAT ( e ) {
    let vat_found = false;
    let uProduct = this.state.product;
    for ( var i = 0; i < this.state.avats.length; i++ ) {
      if ( this.state.avats [ i ].iref === e ) {
        uProduct.vat = this.state.avats [ i ];
        vat_found = true;
      } else {
      }
    }
    this.setState ( { product: uProduct } );
    if ( vat_found ) this.setState ( { vat_valid: true } );
    else this.setState ( { vat_valid: false } );
    this.setState ( { somethingHasChanged: true } );
  }

  changeCategory ( e ) {
    let categorySet = false;
    let uProduct = this.state.product;
    for ( var i = 0; i < this.state.acategories.length; i++ ) {
      if ( this.state.acategories [ i ].iref === e ) {
        uProduct.pCategory = this.state.acategories [ i ];
        categorySet = true; 
      }
    }
    this.setState ( { product: uProduct } );
    this.getSubCategories ( e );

    if ( categorySet === true )
      this.setState ( { category_valid: true } );
    else
      this.setState ( { category_valid: false } );

    this.setState ( { somethingHasChanged: true } );
  }

  changeSubCategory ( e ) {
    let subCategorySet = false;
    let uProduct = this.state.product;
    for ( var i = 0; i < this.state.asubCategories.length; i++ ) {
      if ( this.state.asubCategories [ i ].iref === e ) {
        uProduct.pSubCategory = this.state.asubCategories [ i ];
        subCategorySet = true;
      }
    }
    this.setState ( { product: uProduct } );

    if ( subCategorySet === true )
      this.setState ( { subcategory_valid: true } );
    else
      this.setState ( { subcategory_valid: false } );

    this.setState ( { somethingHasChanged: true } );
  }

  changeStatus ( e ) {
    let uProduct = this.state.product;
    uProduct.status = parseInt ( e );
    this.setState ( { product: uProduct } );
    this.setState ( { somethingHasChanged: true } );
  }
  
  changeType ( e ) {
    let uProduct = this.state.product;
    uProduct.pType = parseInt ( e );
    this.setState ( { product: uProduct } );
    this.setState ( { somethingHasChanged: true } );
  }

  changeUnitType ( e ) {
    let uProduct = this.state.product;
    uProduct.unitType = parseInt ( e );
    this.setState ( { product: uProduct } );
    this.setState ( { somethingHasChanged: true } );
  }

  pushData ( e ) {

    if ( this.state.edit && this.state.somethingHasChanged && this.state.name_valid && this.state.description_valid &&
      this.state.sku_valid && this.state.class_valid && this.state.category_valid && this.state.subcategory_valid ) {
      
      let uProduct = this.state.product;
      let allergensNew = [];
      if ( uProduct.allergens ) {
        for ( var i = 0; i < uProduct.allergens.length; i++ ) {   
          let iref = null;
          if ( uProduct.allergens [ i ].iref ) iref = uProduct.allergens [ i ].iref;
          else iref = uProduct.allergens [ i ];
          if ( iref ) allergensNew.push ( iref );
        }
      }
      uProduct [ "allergensNew" ] = allergensNew;
      uProduct.modifiedOn = "";
      uProduct.createdOn = "";

      let rdata = this.state.client.pushData ( Config.API_URL_PRODUCT_ADD, uProduct );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          uProduct = this.state.product;
          uProduct.iref = rdata.iref;
          this.setState ( { product: uProduct } );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified product ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.props.productUpdated ( );
            this.setState ( { somethingChanged: false } );
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Add/Update product failed !", rdata.message , "error" );
        }
      }
    } else {
      swal ( "Error", "Add/Update product failed !", "error" );
    }
  }

  render() {

    ////console.log ( this.props );
    let product_iref = "";
    let product_name = "";
    let product_description = "";
    let product_image = noImage;
    let product_createdOn = "";
    let product_modifiedOn = "";
    let product_type = 9;
    let product_unitType = 9;
    let product_unitValue = 0;
    let product_kcal = 0;
    let product_ean = "none";
    let product_sku = "none";
    let product_class_iref = "";
    let product_class_name = "";
    let product_class_image = noImage;
    let product_Category_iref = "";
    let product_Category_name = "";
    let product_Category_image = noImage;
    let product_SubCategory_iref = "";
    let product_SubCategory_name = "";
    let product_SubCategory_image = noImage;
    let product_status = 0;
    let product_status_image = noImage;
    let product_status_alt = "Unknown"
    let allergens;
    let product_vat = 0;
    let product_vat_iref = "";
    let productTypeImage = noImage;
    let productTypeText = "undefined";

    let allergens_arahide = "A";
    let allergens_dioxid = "D";
    let allergens_grau = "G";
    let allergens_lapte = "L";
    let allergens_mustar = "M";
    let allergens_ou = "O";
    let allergens_peste = "P";
    let allergens_porumb = "P";
    let allergens_seminte = "S";
    let allergens_soia = "S";

    let allergensView = ""; 
    let allergensRender = "";

    let askPrice = askPrice_off;
    let askQuantity = askQuantity_off;
    let canCombo = canCombo_off;
    let canGenerateFidelityPoints = canGenerateFidelityPoints_off;
    let hasPackaging = hasPackaging_off;
    let hasRecipe = hasRecipe_off;
    let isDeliverable = isDeliverable_off;
    let isDiscountable = isDiscountable_off;
    let isForSale = isForSale_off;
    let isFractionable = isFractionable_off;
    let isNew = isNew_off;
    let isPurchased = isPurchased_off;
    let isSellable = isSellable_off;
    let isSoldAlone = isSoldAlone_off;
    let selfService = selfService_off;
    let useScale = useScale_off;

    let productUnittype_image = noImage;
    let productUnittype_text = "undefined";

    let spec = "";
    let relation = "";
    let grossWeight = 0.0;

    let productImages = [];

    if ( this.state.product ) {

      let product = this.state.product;
      if ( product.iref ) product_iref = product.iref;
      if ( product.name ) product_name = product.name;      
      if ( product.description ) product_description = product.description;
      if ( product.status !== undefined ) product_status = product.status;

      if ( product.pType !== undefined ) product_type = parseInt ( product.pType );
      if ( product.unitType !== undefined ) product_unitType = parseInt ( product.unitType );
      if ( product.unitValue !== undefined ) product_unitValue = parseFloat ( product.unitValue );
      if ( product.kcal !== undefined ) product_kcal = parseInt ( product.kcal );
      
      
      if ( product.createdOn ) product_createdOn = product.createdOn; 
      if ( product.modifiedOn ) product_modifiedOn = product.modifiedOn;     
      if ( product.ean ) product_ean = product.ean;      
      if ( product.sku ) product_sku = product.sku;
      
      if ( product.pClass ) {
        product_class_iref = product.pClass.iref;
        if( product.pClass.name && product.pClass.name !== "null" )
          product_class_name = "Product Class: " + product.pClass.name;
        if( product.pClass.image && product.pClass.image !== "null" )
        product_class_image = product.pClass.image;
        }
      
      if ( product.pCategory ) {
        product_Category_iref = product.pCategory.iref;
        if( product.pCategory.name && product.pCategory.name !== "null" )
          product_Category_name = "Product Category: " + product.pCategory.name;
        if( product.pCategory.image && product.pCategory.image !== "null" )
          product_Category_image = product.pCategory.image;
      }
      
      if ( product.pSubCategory ) {
        product_SubCategory_iref = product.pSubCategory.iref;
        if( product.pSubCategory.name && product.pSubCategory.name !== "null" )
          product_SubCategory_name = "Product Subcategory: " + product.pSubCategory.name;
        if( product.pSubCategory.image && product.pSubCategory.image !== "null" )
          product_SubCategory_image = product.pSubCategory.image;
      }

      if ( product.vat ) {
        product_vat_iref = product.vat.iref;
        product_vat = product.vat.sell;
      }

      switch ( product.status ) {
        case 0:
          product_status_image = registered;
          product_status_alt = "Registered";
          break;
        case 1:
          product_status_image = idle;
          product_status_alt = "Idle";
          break;
        case 2:
          product_status_image = active;
          product_status_alt = "Active";
          break;
        case 9:
          product_status_image = discontinued;
          product_status_alt = "Discontinued";
          break;
      }

      if ( product.askPrice === true ) askPrice = askPrice_on;
      if ( product.askQuantity === true ) askQuantity = askQuantity_on;
      if ( product.canCombo === true ) canCombo = canCombo_on;
      if ( product.canGenerateFidelityPoints === true ) canGenerateFidelityPoints = canGenerateFidelityPoints_on;
      if ( product.hasPackaging === true ) hasPackaging = hasPackaging_on;
      if ( product.hasRecipe === true ) hasRecipe = hasRecipe_on;
      if ( product.isDeliverable === true ) isDeliverable = isDeliverable_on;
      if ( product.isDiscountable === true ) isDiscountable = isDiscountable_on;
      if ( product.isForSale === true ) isForSale = isForSale_on;
      if ( product.isFractionable === true ) isFractionable = isFractionable_on;
      if ( product.isNew === true ) isNew = isNew_on;
      if ( product.isPurchased === true ) isPurchased = isPurchased_on;
      if ( product.isSellable === true ) isSellable = isSellable_on;
      if ( product.isSoldAlone === true ) isSoldAlone = isSoldAlone_on;
      if ( product.selfService === true ) selfService = selfService_on;
      if ( product.useScale === true ) useScale = useScale_on;

      if (product.spec) spec = product.spec;
      if (product.relation) relation = product.relation;
      if (product.grossWeight !== undefined) grossWeight = parseFloat(product.grossWeight);

      
      let productHasImage = false;
      if ( product.productImage_product ) {
        if ( product.productImage_product.length > 0 ) {
          productHasImage = true;
          if ( product.productImage_product[0].image )
            product_image = product.productImage_product[0].image;
          productImages = product.productImage_product.map ( (pImage) => {
            if ( pImage.isMain === true )
              product_image = pImage.image;
          });
        //Former KImages table        
        }
      }

      productImages = <KImagesTable
                          images = {product.productImage_product}
                          edit = {this.state.edit}
                          viewDetails = {this.state.viewDetails}
                          saveURL = {Config.API_URL_PRODUCT_ADD_IMAGE}
                          removeURL = {Config.API_URL_PRODUCT_REMOVE_IMAGE}
                          parentIref = {product.iref}
                          imageUpdated = {this.imageUpdated}
                        />;


      if ( productHasImage ) {
        let fileName = product_image.replace(/\.[^/.]+$/, "")
        let fileExtensions = product_image.split(".").pop();
        product_image = fileName + "_m." + fileExtensions;
      }

      if ( this.state.allergensList ) {
        let allergenList = this.state.allergensList;
        let allergensArr = Object.entries ( allergenList );      
        allergensRender = allergensArr.map ( (allergen) => {
          let img_on = "";
          let img_off = "";
          let name = "";
          let img = "";
          let iref = "";
          if ( allergen.length === 2 ) {          
            iref = allergen [ 1 ].iref;
            name = allergen [ 1 ].name;
            img_on = allergen [ 1 ].image;
            img_off = allergen [ 1 ].image_off;
          } else {
            iref = allergen [ 0 ].iref;
            name = allergen [ 0 ].name;
            img_on = allergen [ 0 ].image;
            img_off = allergen [ 0 ].image_off;
          }
          img = img_off;
          if ( product.allergens ) {
            allergens = product.allergens.map ( (allergen) => {
              if ( allergen.iref === iref ) img = img_on;
            });
          }
        return <Allergen
          key = {iref}
          iref = {iref}
          name = {name}
          image = {img}
          onClickFunction = {this.flagAllergen}
          edit = {this.state.edit}
          />
        });
      }

      
      if (product_type !== null && product_type !== undefined) {
        productTypeImage = BackOfficeConfig.PRODUCT_TYPES_WITH_ICONS [product_type].image;
        productTypeText = BackOfficeConfig.PRODUCT_TYPES_WITH_ICONS [product_type].name;
      }

      if (product_unitType !== null && product_unitType !== undefined) {
        productUnittype_image = BackOfficeConfig.PRODUCT_UNIT_TYPES_WITH_ICONS [product_unitType].image;
        productUnittype_text = BackOfficeConfig.PRODUCT_UNIT_TYPES_WITH_ICONS [product_unitType].name;
      } 
    }

    let saveButtonVisible = false;
    if ( this.state.edit && this.state.somethingHasChanged && 
        this.state.name_valid && this.state.description_valid &&
        /* this.state.ean_valid &&  */this.state.sku_valid && this.state.class_valid &&
        this.state.category_valid && this.state.subcategory_valid &&
        this.state.kcal_valid && this.state.unitValue_valid && this.state.vat_valid)
        saveButtonVisible = true;

    return (
      /* , display: 'flex' */
    <Card sx={{ borderRadius: '16px', m: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardHeader
          avatar={  
            <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>      
                <Avatar alt={product_status_alt} src={product_status_image} title={product_status_alt}>
                </Avatar>   
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>      
                <Avatar alt={productTypeText} src={productTypeImage} title={productTypeText}>
                </Avatar>   
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                <Typography variant="subtitle1" color="text.secondary">
                  {product_iref}
                </Typography>
              </Box>
            </Box>         
          }             
          action={            
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
              <EditIcon
                sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}                
              />
            </IconButton>
          }
          title={product_name}
          subheader=""
          titleTypographyProps={{variant:'h5' }}
        />

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>

        <CardMedia
          component="img"
          height="120"
          sx={{ width: 128, marginLeft: 1 }}
          image={product_image}
          alt={product_name}
        />

        {this.state.edit ?
        <Box sx={{ flexDirection: 'column', display: "block", width: "30%", marginLeft: 1 }}>
          <DropDown
            name="Status"
            value={product_status !== undefined ? product_status : ''}
            doptions={BackOfficeConfig.PRODUCT_STATUSES_WITH_ICONS}
            hasIcons={true}   
            minWidth={220}
            maxWidth={220}
            onDark={false}
            onChangeFunction={this.changeStatus}
            marginTop={8}
            marginLeft={0}
            margin={0}
          />

          <DropDown
            name="Type"
            value={product_type !== undefined ? product_type : ''}
            doptions={BackOfficeConfig.PRODUCT_TYPES_WITH_ICONS}
            hasIcons={true}   
            minWidth={220}
            maxWidth={220}
            onDark={false}
            onChangeFunction={this.changeType}
            marginTop={8}
            marginLeft={0}
            margin={0}
          />

          <DropDown
            name="Class"
            value={product_class_iref !== undefined ? product_class_iref : ''}
            doptions={this.state.classes}
            hasIcons={true}   
            minWidth={220}
            maxWidth={220}
            onDark={false}
            onChangeFunction={this.changeClass}
            marginTop={8}
            marginLeft={0}
            margin={0}
            error={this.state.class_valid === false}
          />

          <DropDown
            name="Category"
            value={product_Category_iref !== undefined ? product_Category_iref : ''}
            doptions={this.state.categories}
            hasIcons={true}   
            minWidth={220}
            maxWidth={220}
            onDark={false}
            onChangeFunction={this.changeCategory}
            marginTop={8}
            marginLeft={0}
            margin={0}
            error={this.state.category_valid === false}
          />

          <DropDown
            name="Sub Category"
            value={product_SubCategory_iref !== undefined ? product_SubCategory_iref : ''}
            doptions={this.state.subCategories}
            hasIcons={true}   
            minWidth={220}
            maxWidth={220}
            onDark={false}
            onChangeFunction={this.changeSubCategory}
            marginTop={8}
            marginLeft={0}
            margin={0}
            error={this.state.subcategory_valid === false}
          />

          <TextField
            id="relation"
            label="Relation"
            value={relation || ''}
            variant="outlined"
            onChange={this.changeValue}
            sx={{minWidth: "30%", marginLeft: 0, marginTop: 1}}
            onFocus={event => { event.target.select(); }}
            inputProps={{maxLength:"64"}}              
          />

        </Box>
        :
        <Box sx={{ flexDirection: 'column', display: "block" }}>
          <CardContent sx={{display: "block", justifyContent: "left"}}>
            <Typography variant="body2" color="text.secondary" sx={{justifyContent: "left"}}>
              {product_description}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{justifyContent: "left"}}>
              {"VAT: " + product_vat + "%"}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{justifyContent: "left"}}>
              {"EAN: " + product_ean}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{justifyContent: "left"}}>
              {"SKU: " + product_sku}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{justifyContent: "left"}}>
              {"KCal: " + product_kcal}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{justifyContent: "left"}}>
              {"Relation: " + relation}
            </Typography>
          </CardContent>
          <CardActions disableSpacing sx={{display: "flex", justifyContent: "flex-end"}}>
            <IconButton aria-label={product_class_name} title={product_class_name}>       
              <Avatar alt={product_class_name} src={product_class_image} sx={{ width: 48, height: 48 }}/>
            </IconButton>
            <IconButton aria-label={product_Category_name} title={product_Category_name}>       
              <Avatar alt={product_Category_name} src={product_Category_image} sx={{ width: 48, height: 48 }}/>
            </IconButton>
            <IconButton aria-label={product_SubCategory_name} title={product_SubCategory_name}>       
              <Avatar alt={product_SubCategory_name} src={product_SubCategory_image} sx={{ width: 48, height: 48 }}/>
            </IconButton>
          </CardActions>
        </Box>
        }

        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '100%' }}>
          {this.state.edit ?
            <Box sx={{ display: "flex", flexDirection: 'row', justifyContent:"right", width: '100%' }}>
              <TextField
                id="name"
                label="Name"
                value={product_name || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "30%", marginLeft: 2, marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"100"}}
                error={this.state.name_valid === false}                
              />
              <TextField
                id="description"
                label="Description"
                value={product_description || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "30%", marginTop: 1, marginLeft: 1, marginRight: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"1024"}}
                error={this.state.description_valid === false}
              />
            </Box>
            : ""
          }
        
        
          <CardActions disableSpacing sx={{display: "block", textAlign: "end"}}>
            {allergensRender}
          </CardActions>
          
          <CardActions disableSpacing sx={{display: "block", textAlign: "end" }}>          
            <IconButton id="askPrice" aria-label="askPrice" title="askPrice" onClick={this.flagOption} disabled={!this.state.edit}>
              <Avatar alt="askPrice" src={askPrice} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="askQuantity" aria-label="askQuantity" title="askQuantity" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="askQuantity" src={askQuantity} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="canCombo" aria-label="canCombo" title="canCombo" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="canCombo" src={canCombo} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="canGenerateFidelityPoints" aria-label="canGenerateFidelityPoints" title="canGenerateFidelityPoints" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="canGenerateFidelityPoints" src={canGenerateFidelityPoints} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="hasPackaging" aria-label="hasPackaging" title="hasPackaging" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="hasPackaging" src={hasPackaging} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="hasRecipe" aria-label="hasRecipe" title="hasRecipe" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="hasRecipe" src={hasRecipe} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="isDeliverable" aria-label="isDeliverable" title="isDeliverable" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isDeliverable" src={isDeliverable} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="isDiscountable" aria-label="isDiscountable" title="isDiscountable" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isDiscountable" src={isDiscountable} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="isForSale" aria-label="isForSale" title="isForSale" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isForSale" src={isForSale} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="isFractionable" aria-label="isFractionable" title="isFractionable" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isFractionable" src={isFractionable} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="isNew" aria-label="isNew" title="isNew" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isNew" src={isNew} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="isPurchased" aria-label="isPurchased" title="isPurchased" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isPurchased" src={isPurchased} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="isSellable" aria-label="isSellable" title="isSellable" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isSellable" src={isSellable} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="isSoldAlone" aria-label="isSoldAlone" title="isSoldAlone" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isSoldAlone" src={isSoldAlone} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="selfService" aria-label="selfService" title="selfService" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="selfService" src={selfService} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="useScale" aria-label="useScale" title="useScale" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="useScale" src={useScale} sx={{ width: 24, height: 24 }}/>
            </IconButton>

            
          
          {this.state.edit ?
            <Box sx={{ flexDirection: 'row', justifyContent:"right", width: '100%' }}>
              <DropDown
                name="VAT"
                value={product_vat_iref !== undefined ? product_vat_iref : ''}
                doptions={this.state.vats}
                hasIcons={false}   
                minWidth={150}
                maxWidth={150}
                onDark={false}
                onChangeFunction={this.changeVAT}
                marginTop={24}
                marginLeft={0}
                margin={0}
                error={this.state.vat_valid === false}
              />
              <TextField
                id="ean"
                label="EAN"
                value={product_ean || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "25%", marginTop: 3, marginLeft: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"100"}}
              />
              <TextField
                id="sku"
                label="SKU"
                value={product_sku || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "25%", marginTop: 3, marginLeft: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"255"}}
                error={this.state.sku_valid === false}
              />
            </Box>            
          :
          ""
          }
          {this.state.edit ?
            <Box sx={{ flexDirection: 'row', justifyContent:"right", width: '100%' }}>
              <DropDown
                name="Unit Type"
                value={product_unitType !== undefined ? product_unitType : ''}
                doptions={BackOfficeConfig.PRODUCT_UNIT_TYPES_WITH_ICONS}
                hasIcons={true}   
                minWidth={150}
                maxWidth={150}
                onDark={false}
                onChangeFunction={this.changeUnitType}
                marginTop={8}
                marginLeft={0}
                margin={0}
              />
              <TextField
                id="unitValue"
                label="Unit Value"
                value={product_unitValue !== undefined ? product_unitValue : ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "25%", marginTop: 1, marginLeft: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"16"}}
                error={this.state.unitValue_valid === false}
                type="number" 
              />
              <TextField
                id="kcal"
                label="KCal"
                value={product_kcal !== undefined ? product_kcal : ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "25%", marginTop: 1, marginLeft: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"16"}}
                error={this.state.kcal_valid === false}
                type="number" 
              />
            </Box>
          :
          ""
          }
          {this.state.edit ?
            <Box sx={{ flexDirection: 'row', justifyContent:"right", width: '100%' }}>
              <TextField
                id="grossWeight"
                label="Gross Weight"
                value={grossWeight !== undefined ? grossWeight : ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "25%", marginTop: 1, marginLeft: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"16"}}
                type="number" 
              />
            </Box>
            : ""
          }

        </CardActions>
        {!this.state.edit ?
          <CardActions disableSpacing sx={{display: "block", textAlign: "end" }}>
            <Box sx={{ display: 'inline-flex', flexDirection: 'row'}}>
              <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                <Avatar alt={productUnittype_text} src={productUnittype_image} title={productUnittype_text} sx={{ width: 32, height:32 }}>
                </Avatar>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                  <Typography variant="body2" color="text.secondary" sx={{justifyContent: "right"}}>
                    {" Unit Value: " + product_unitValue}
                  </Typography>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2}}>
                <Avatar alt="Gross Weight" src={grossWeight_on} title="Gross Weight" sx={{ width: 32, height:32 }}>
                </Avatar>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                  <Typography variant="body2" color="text.secondary" sx={{justifyContent: "right"}}>
                    {" Gross weight: " + grossWeight}
                  </Typography>
              </Box>
            </Box>
          </CardActions>
        : "" }

        </Box>
        
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "5px", paddingBottom: "10px" }}>
          {this.state.edit ?
            <TextareaAutosize
              id="spec"
              label="Specifications"
              variant="outlined"
              value={spec || ''}
              onChange={this.changeValue}
              onFocus={event => { event.target.select(); }}          
              minRows={5}
              maxRows={10}
              required={false}
              placeholder="Specifications"
            />
          : spec
          }
        </Box>


        <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "0px", paddingBottom: "0px" }}>
          {this.state.edit && saveButtonVisible ?
            <Button
              variant="contained"
              size="small"
              sx={{ color: "#e3e3e5" }}
              startIcon={<SaveIcon />}
              onClick={this.pushData}
            >
              Save
            </Button>
            : product_modifiedOn ? "Modified on: " + product_modifiedOn : ""
          }
        </Box>

        <ExpandMore
          expand={this.state.expanded}
          onClick={this.handleExpandClick}
          aria-expanded={this.state.expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>

        {/* <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}> */}

          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              {productImages}
            </CardContent>
              
            </Collapse>

        {/* </Box> */}

        

      </Box>
    </Card>
   
    );

  }
}

export default Product;
